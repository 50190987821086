import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Home from './components/Home/Main';
import Admin from './components/Admin/Admin';
// import { Account } from './components/Accounts';
// import LoginState from './components/LoginState/LoginState';

// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

const App = () => (
	<Router>
		{/* <Header /> */}
		<Switch>
			<Route exact path="/">
				<Home />
			</Route>
			{/* <Route path="/cart">
				<Cart />
			</Route>
			<Route path="/checkout">
				<Checkout />
			</Route>
			<Route exact path="/books">
				<Books />
			</Route>
			<Route path="/books/:id" children={<BookDetails></BookDetails>}></Route> */}
			<Route path="/admin">
				<Admin />
			</Route>
			{/* <Route path="*">
				<Error />
			</Route> */}
		</Switch>
	</Router>
	// <div>
	// 	<AmplifySignOut />
	// 	My App
	// </div>
);

export default App;
