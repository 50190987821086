import React from 'react';
import Menu from './Menu/Menu';
//import Body from './Body';
//import Footer from './Footer';
import classes from './Main.css';
//import Message from '../Utils/Message';

//import { AccountContext } from '../Accounts';

export default () => {
	console.log('[Main.js] Inside Main.js component');
	return (
		<div className={classes}>
			<Menu />
		</div>
	);
};
