import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { Route, Router, Switch } from 'react-router-dom';
// // import { createBrowserHistory } from 'history';
// import history from './components/Utils/history';
// import { Account } from './components/Accounts';
// import ProtectedRoute from './components/Utils/ProtectedRoute';
// import Login from './components/Landing/Login/Login';
// import Main from './components/Home/Main';
// import LandingPage from './components/Landing/LandingPage';
// import Alert from './components/Utils/Alert';
//import Message from './components/Utils/Message';

// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

// const history = createBrowserHistory();
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
