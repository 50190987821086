import React, { useContext } from 'react';
// import { AccountContext } from './../../Accounts';
// import { alertService } from '../../Utils/AlertService';
//import classes from './Menu.css';

export default () => {
	// const { logout } = useContext(AccountContext);

	return (
		<div>
			<div>Some other menu stuff</div>
			<div>
				Logged In!
				{/* {alertService.success('You are logged in!', {
					autoClose: false,
					keepAfterRouteChange: true,
				})} */}
				{/* <button onClick={logout}>Logout</button> */}
				<button>Logout</button>
			</div>
		</div>
	);
};
