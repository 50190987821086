/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//     "aws_project_region": "us-west-2"
// };

// export default awsmobile;

const config = {
	Auth: {
		// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
		identityPoolId: 'us-east-1:18c00ecd-34ec-436b-ba31-34c32cc7074b',

		// REQUIRED - Amazon Cognito Region
		region: 'us-east-1',

		// OPTIONAL - Amazon Cognito Federated Identity Pool Region
		// Required only if it's different from Amazon Cognito Region
		identityPoolRegion: 'us-east-1',

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'us-east-1_tomr7sYFv',

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: '4t2hp65odpr60pn8vam68effrh',
	},

	// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
	//mandatorySignIn: false,

	// OPTIONAL - Configuration for cookie storage
	// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
	// cookieStorage: {
	// 	// REQUIRED - Cookie domain (only required if cookieStorage is provided)
	// 	domain: '.yourdomain.com',
	// 	// OPTIONAL - Cookie path
	// 	path: '/',
	// 	// OPTIONAL - Cookie expiration in days
	// 	expires: 365,
	// 	// OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
	// 	sameSite: 'strict' | 'lax',
	// 	// OPTIONAL - Cookie secure flag
	// 	// Either true or false, indicating if the cookie transmission requires a secure protocol (https).
	// 	secure: true,
	// },

	// OPTIONAL - customized storage object
	Storage: {
		bucket: 'bmm.digitalica.io.public.videos',
		region: 'us-east-1',
	},

	// // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
	// authenticationFlowType: 'USER_PASSWORD_AUTH',

	// // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
	// clientMetadata: { myCustomKey: 'myCustomValue' },

	// // OPTIONAL - Hosted UI configuration
	// oauth: {
	// 	domain: 'your_cognito_domain',
	// 	scope: [
	// 		'phone',
	// 		'email',
	// 		'profile',
	// 		'openid',
	// 		'aws.cognito.signin.user.admin',
	// 	],
	// 	redirectSignIn: 'http://localhost:3000/',
	// 	redirectSignOut: 'http://localhost:3000/',
	// 	responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
	// },
};

export default config;
